import { useState } from 'react';
import { firebaseAuth } from '../firebaseAuth';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { Grid } from '@mui/material';
import i18n from '../i18n';
import { Constants } from '../constants';
import I18n from '../i18n';
import Notification from '../components/Notification';
import styles from '../styles/Common.module.css';
import { level1ApiEndpoint } from '../utils/apiEndpointUrl';
import { checkSignUpForm } from '../utils/check_signup_form';
import { XCircleIcon } from '@heroicons/react/24/outline';

export default function SignUp() {
  const [signUpForm, setSignUpForm] = useState({
    email: '',
    password: '',
    passwordConfirmation: '',
    invitationCode: '',
    organizationType: 'business',
    organizationName: '',
    organizationAddress: '',
    adminName: '',
    organizationPhoneNumber: '',
    organizationPostalCode: '',
    cooperateImprovement: true,
  });

  const [openNotification, setOpenNotification] = useState(false);
  const [httpResponseResult, setHttpResponseResult] = useState({
    success: '',
    error: '',
  });
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);

  const signUp = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formError = checkSignUpForm({
      email: signUpForm.email,
      password: signUpForm.password,
      passwordConfirmation: signUpForm.passwordConfirmation,
    });
    if (formError) {
      setFormErrorMessage(formError);
      return;
    }
    const formDataForCheckInvitationCode = new FormData();
    formDataForCheckInvitationCode.append(
      'invitationCode',
      signUpForm.invitationCode
    );

    fetch(`${level1ApiEndpoint()}/checkInvitationCode`, {
      method: 'POST',
      body: formDataForCheckInvitationCode,
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          createUserWithEmailAndPassword(
            firebaseAuth,
            signUpForm.email,
            signUpForm.password
          )
            .then((userCredential) => {
              userCredential.user
                .getIdToken(true)
                .then((idToken) => {
                  const formData = new FormData();
                  formData.append('email', signUpForm.email);
                  formData.append(
                    'organizationName',
                    signUpForm.organizationName
                  );
                  formData.append(
                    'organizationPostalCode',
                    signUpForm.organizationPostalCode
                  );
                  formData.append(
                    'organizationAddress',
                    signUpForm.organizationAddress
                  );
                  formData.append(
                    'organizationPhoneNumber',
                    signUpForm.organizationPhoneNumber
                  );
                  formData.append(
                    'organizationType',
                    signUpForm.organizationType
                  );
                  formData.append('adminName', signUpForm.adminName);
                  formData.append(
                    'cooperateImprovement',
                    String(signUpForm.cooperateImprovement)
                  );

                  fetch(`${level1ApiEndpoint()}/users`, {
                    headers: { Authorization: idToken },
                    method: 'POST',
                    body: formData,
                    credentials: 'include',
                  })
                    .then((response) => {
                      if (response.ok) {
                        const message = structuredClone(httpResponseResult);
                        message.success = I18n.t(
                          'individual_pages.sign_up.successfully_created_user'
                        );
                        setHttpResponseResult(message);
                        window.location.replace('/resources');
                      } else {
                        throw new Error(
                          '予期せぬエラーが発生しました。カスタマーサポートまでご連絡ください。'
                        );
                      }
                    })
                    .catch(() => {
                      throw new Error(
                        '予期せぬエラーが発生しました。カスタマーサポートまでご連絡ください。'
                      );
                    });
                })
                .catch(() => {
                  setHttpResponseResult({
                    error:
                      '予期せぬエラーが発生しました。カスタマーサポートまでご連絡ください。',
                    success: '',
                  });
                  setOpenNotification(true);
                  return;
                });
            })
            .catch((error) => {
              const message = structuredClone(httpResponseResult);
              message.error = error.message;
              setHttpResponseResult(message);
              setOpenNotification(true);
              return;
            });
        } else {
          setHttpResponseResult({
            error: '招待コードが不正です。',
            success: '',
          });
          setOpenNotification(true);
          return;
        }
      })
      .catch((err) => {
        setHttpResponseResult({
          error:
            '予期せぬエラーが発生しました。カスタマーサポートまでご連絡ください。',
          success: '',
        });
        setOpenNotification(true);
        return;
      });
  };
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 md:py-12 lg:px-8">
      {httpResponseResult.error && (
        <Notification
          alertMessage={httpResponseResult.error}
          openNotification={openNotification}
          setOpenNotification={setOpenNotification}
        />
      )}
      {httpResponseResult.success && (
        <Notification
          infoMessage={httpResponseResult.success}
          openNotification={openNotification}
          setOpenNotification={setOpenNotification}
        />
      )}
      <h1 className="text-2xl text-center">SIGQ Cloud Linker 新規会員登録</h1>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        {formErrorMessage && (
          <div className="rounded-md bg-red-50 p-4 mb-5">
            <div className="flex">
              <div className="flex-shrink-0">
                <XCircleIcon
                  aria-hidden="true"
                  className="h-5 w-5 text-red-400"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">
                  入力されたフォームで誤りがあります
                </h3>
                <div className="mt-2 text-sm text-red-700">
                  {formErrorMessage}
                </div>
              </div>
            </div>
          </div>
        )}
        <form className="space-y-6" onSubmit={signUp}>
          <div>
            <label
              htmlFor="location"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              登録種別
            </label>
            <select
              id="userType"
              name="userType"
              defaultValue="business"
              onChange={(e) => {
                setSignUpForm(
                  Object.assign({}, signUpForm, {
                    organizationType: e.target.value,
                  })
                );
              }}
              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            >
              <option value="business">ビジネス利用（法人・個人事業主）</option>
              <option value="personal">個人利用</option>
            </select>
          </div>
          {signUpForm.organizationType === 'personal' && (
            <div>
              <label
                htmlFor="organizationName"
                className="block text-sm font-medium leading-6 text-gray-900 mt-5"
              >
                ワークスペース名
              </label>
              <div className="mt-2">
                <input
                  id="organizationName"
                  name="organizationName"
                  type="text"
                  value={signUpForm.organizationName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSignUpForm(
                      Object.assign({}, signUpForm, {
                        organizationName: e.target.value,
                      })
                    );
                  }}
                  required
                  className={styles.formInput}
                />
              </div>
            </div>
          )}
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900 mt-5"
            >
              {i18n.t('noun.email')}
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                value={signUpForm.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSignUpForm(
                    Object.assign({}, signUpForm, {
                      email: e.target.value,
                    })
                  );
                }}
                required
                className={styles.formInput}
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {i18n.t('noun.password')}
              </label>
            </div>
            <div className="mt-2 flex items-center">
              <input
                id="password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Abcd1234?!"
                required
                value={signUpForm.password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSignUpForm(
                    Object.assign({}, signUpForm, {
                      password: e.target.value,
                    })
                  );
                }}
                className={styles.formInput}
              />
              <button
                className="ml-1"
                type="button"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                    />
                  </svg>
                )}
                {!showPassword && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                {i18n.t('noun.password_confirmation')}
              </label>
            </div>
            <div className="mt-2 flex items-center">
              <input
                id="passwordConfirmation"
                name="passwordConfirmation"
                type={showPasswordConfirmation ? 'text' : 'password'}
                required
                value={signUpForm.passwordConfirmation}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSignUpForm(
                    Object.assign({}, signUpForm, {
                      passwordConfirmation: e.target.value,
                    })
                  );
                }}
                className={styles.formInput}
              />
              <button
                className="ml-1"
                type="button"
                onClick={() => {
                  setShowPasswordConfirmation(!showPasswordConfirmation);
                }}
              >
                {showPasswordConfirmation && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                    />
                  </svg>
                )}
                {!showPasswordConfirmation && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
          {signUpForm.organizationType === 'business' && (
            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="organizationName"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  法人名（個人事業主の場合には屋号）
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="organizationName"
                  name="organizationName"
                  type="text"
                  required
                  maxLength={20}
                  value={signUpForm.organizationName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSignUpForm(
                      Object.assign({}, signUpForm, {
                        organizationName: e.target.value,
                      })
                    );
                  }}
                  className={styles.formInput}
                />
              </div>

              <div className="flex items-center justify-between mt-5">
                <label
                  htmlFor="invitationCode"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  郵便番号
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="organizationPostalCode"
                  name="organizationPostalCode"
                  type="number"
                  maxLength={10}
                  required
                  value={signUpForm.organizationPostalCode}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSignUpForm(
                      Object.assign({}, signUpForm, {
                        organizationPostalCode: e.target.value,
                      })
                    );
                  }}
                  className={styles.formInput}
                />
              </div>

              <div className="flex items-center justify-between mt-5">
                <label
                  htmlFor="invitationCode"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  事業所所在地
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="organizationAddress"
                  name="organizationAddress"
                  type="text"
                  required
                  value={signUpForm.organizationAddress}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSignUpForm(
                      Object.assign({}, signUpForm, {
                        organizationAddress: e.target.value,
                      })
                    );
                  }}
                  className={styles.formInput}
                />
              </div>

              <div className="flex items-center justify-between mt-5">
                <label
                  htmlFor="invitationCode"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  担当者名
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="adminName"
                  name="adminName"
                  type="text"
                  required
                  maxLength={30}
                  value={signUpForm.adminName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSignUpForm(
                      Object.assign({}, signUpForm, {
                        adminName: e.target.value,
                      })
                    );
                  }}
                  className={styles.formInput}
                />
              </div>

              <div className="flex items-center justify-between mt-5">
                <label
                  htmlFor="invitationCode"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  担当者連絡先
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="organizationPhoneNumber"
                  name="organizationPhoneNumber"
                  type="tel"
                  pattern="^(0([1-9]{1}-?[1-9]\d{3}|[1-9]{2}-?\d{3}|[1-9]{2}\d{1}-?\d{2}|[1-9]{2}\d{2}-?\d{1})-?\d{4}|0[789]0-?\d{4}-?\d{4}|050-?\d{4}-?\d{4})$"
                  required
                  value={signUpForm.organizationPhoneNumber}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSignUpForm(
                      Object.assign({}, signUpForm, {
                        organizationPhoneNumber: e.target.value,
                      })
                    );
                  }}
                  className={styles.formInput}
                />
              </div>
            </div>
          )}

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="invitationCode"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                紹介コード
              </label>
            </div>
            <div className="mt-2">
              <input
                id="invitationCode"
                name="invitationCode"
                type="text"
                required
                value={signUpForm.invitationCode}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSignUpForm(
                    Object.assign({}, signUpForm, {
                      invitationCode: e.target.value,
                    })
                  );
                }}
                className={styles.formInput}
              />
            </div>
          </div>
          <div className="relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                id="cooperateImprovement"
                name="cooperateImprovement"
                type="checkbox"
                checked={signUpForm.cooperateImprovement}
                onChange={(e) => {
                  setSignUpForm(
                    Object.assign({}, signUpForm, {
                      cooperateImprovement: e.target.checked,
                    })
                  );
                }}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
            </div>
            <div className="ml-3 text-sm leading-6">
              <label
                htmlFor="cooperateImprovement"
                className="font-medium text-gray-900"
              >
                プロダクト改善のためにユーザーインタビューに協力する
              </label>{' '}
            </div>
          </div>
          <div className="text-xs text-gray-500">
            <p>現時点では当社がご招待した方のみ新規登録ができます。</p>
            <p>{I18n.t('individual_pages.sign_up.agree_to_signup1')}</p>
            <p className="mt-2">
              {I18n.t('individual_pages.sign_up.agree_to_signup2')}(
              <a
                href="https://storage.googleapis.com/sigq-public-docs/%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84.pdf"
                className="hover:underline"
                target="_blank"
              >
                {I18n.t('noun.terms')}
              </a>
              /
              <a
                href="/privacy-policy"
                className="hover:underline"
                target="_blank"
              >
                {I18n.t('noun.privacy_policy')}
              </a>
              )
            </p>
            <p>{I18n.t('individual_pages.sign_up.agree_to_signup3')}</p>
          </div>
          <div>
            <button className={`${styles.button} w-full`} type="submit">
              {i18n.t('individual_pages.sign_up.signup_with_confirmation')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
