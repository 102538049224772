import { User } from 'firebase/auth';
import { Organization, Resource, ResourceAccessHistory } from '../types';
import { level3ApiEndpoint } from '../utils/apiEndpointUrl';
import { useEffect, useState } from 'react';
import i18n from '../i18n';
import PageNavigation from '../components/pageNavigation';
import { getUAStr } from '../utils/user_agent';
import { parseDate } from '../utils/date';
import { PlusIcon } from '@heroicons/react/24/outline';
import { requestToBackend } from '../utils/rpcLogics';

export default function AccessLogExplorer({
  currentUser,
  currentOrganization,
}: {
  currentUser: User;
  currentOrganization: Organization;
}) {
  const [searchForm, setSearchForm] = useState({
    fileId: '',
    emails: '',
    ips: '',
  });
  const [histories, setHistories] = useState<ResourceAccessHistory[]>([]);
  const [resources, setResources] = useState<Resource[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const dataPerPage = 50;
  const [isSearchPanelOpen, setIsSearchPanelOpen] = useState(false);

  const loadResourceAccessControl = async () => {
    const formData = new FormData();
    formData.append('fileId', searchForm.fileId);
    formData.append('emails', searchForm.emails);
    formData.append('ips', searchForm.ips);
    const [histories, error] = await requestToBackend<ResourceAccessHistory[]>(
      currentUser,
      `${level3ApiEndpoint()}/histories?organizationId=${
        currentOrganization.id
      }`,
      'GET',
      formData
    );
    if (error) {
      console.log({ error });
    } else {
      if (histories) {
        setHistories(histories);
      }
    }
  };
  const postSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    loadResourceAccessControl();
  };
  const loadResources = async () => {
    const [resources, error] = await requestToBackend<Resource[]>(
      currentUser,
      `${level3ApiEndpoint()}/resources?organizationId=${
        currentOrganization.id
      }`,
      'GET'
    );
    if (error) {
      console.log({ error });
    } else {
      if (resources) {
        setResources(resources);
      }
    }
  };

  useEffect(() => {
    loadResources();
    loadResourceAccessControl();
  }, []);

  // @ts-ignore
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center mt-10">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            {i18n.t('noun.access_log')}
          </h1>
        </div>
      </div>
      <div className="mt-2 sm:mt-0 sm:ml-16 sm:flex-none text-right pt-3 text-gray-400 font-light">
        {i18n.t('noun.timezone')}:{' '}
        {Intl.DateTimeFormat().resolvedOptions().timeZone}
      </div>
      <button
        type="button"
        className="rounded-full bg-indigo-600 px-2.5 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        hidden={isSearchPanelOpen}
        onClick={() => {
          setIsSearchPanelOpen(!isSearchPanelOpen);
        }}
      >
        検索条件を開く
      </button>
      <button
        type="button"
        className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        onClick={() => {
          setIsSearchPanelOpen(!isSearchPanelOpen);
        }}
        hidden={!isSearchPanelOpen}
      >
        検索条件を閉じる
      </button>
      <div hidden={!isSearchPanelOpen}>
        <div>
          <form
            className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
            onSubmit={postSearchForm}
          >
            <div className="px-4 py-6 sm:p-8">
              <div>
                <div className="col-span-12">
                  <label
                    htmlFor="country"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    ファイル名
                  </label>
                  <div className="mt-2">
                    <select
                      id="country"
                      name="country"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={(e) => {
                        setSearchForm(
                          Object.assign(searchForm, { fileId: e.target.value })
                        );
                      }}
                    >
                      {resources &&
                        resources
                          .map((resource) => (
                            <option key={resource.id} value={resource.id}>
                              {resource.name} ({resource.id})
                            </option>
                          ))
                          .concat(
                            <option key={'all'} value={'all'}>
                              全てのファイルを選択
                            </option>
                          )}
                    </select>
                  </div>
                </div>

                <div className="col-span-12">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    メールアドレス
                  </label>
                  <div className="mt-2">
                    <input
                      id="emails"
                      name="emails"
                      type="text"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={(e) => {
                        setSearchForm(
                          Object.assign(searchForm, { emails: e.target.value })
                        );
                      }}
                    />
                  </div>
                </div>

                <div className="col-span-12">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    IPアドレス
                  </label>
                  <div className="mt-2">
                    <input
                      id="ips"
                      name="ips"
                      type="text"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={(e) => {
                        setSearchForm(
                          Object.assign(searchForm, { ips: e.target.value })
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="grid gap-4 grid-cols-2">
                  <div>
                    <label
                      htmlFor="searchFrom"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      アクセス (From)
                    </label>
                    <div className="mt-2">
                      <input
                        id="searchFrom"
                        name="searchFrom"
                        type="datetime-local"
                        autoComplete="given-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="searchTo"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      アクセス (To)
                    </label>
                    <div className="mt-2">
                      <input
                        id="searchTo"
                        name="searchTo"
                        type="datetime-local"
                        autoComplete="family-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
              <button
                type="submit"
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                検索
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="mt-2 flow-root">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-scroll shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {i18n.t('noun.resource')}
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {i18n.t('noun.accessed_at')}
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {i18n.t('noun.email')}
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {i18n.t('noun.ip')}
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {i18n.t('noun.user_agent')}
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Country
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      City
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {histories?.length > 0 &&
                    histories
                      .slice(
                        pageNumber * dataPerPage - dataPerPage,
                        pageNumber * dataPerPage
                      )
                      .map((history) => (
                        <tr key={history.id}>
                          <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                            {history.resourceName}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {parseDate(history.accessedAt)}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {history.accessMeta.email}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {history.accessMeta.ip}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {getUAStr(history.accessMeta.userAgent)}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {history.geoInfo.countryLong}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {history.geoInfo.city}
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
            <div className="my-10">
              <PageNavigation
                numPages={Math.ceil(histories?.length / dataPerPage)}
                setPage={setPageNumber}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
